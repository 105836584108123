// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDA7M7CM4YE0-0OCGeyRY7h8_5OWSjfugo",
  authDomain: "bwm-hrms.firebaseapp.com",
  databaseURL: "https://bwm-hrms-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "bwm-hrms",
  storageBucket: "bwm-hrms.appspot.com",
  messagingSenderId: "306836457060",
  appId: "1:306836457060:web:0798e4624477c359338171",
  measurementId: "G-ZE94GLDQE5" // Keep this if you plan to use analytics
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

// Export the app and database for use in other parts of your application
export { app, database, auth };
