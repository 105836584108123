// src/App.jsx
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Main from './components/Main';
import Login from './pages/Login'; 
import Register from './pages/Register'; 
import Dashboard from './pages/Dashboard'; // Included Dashboard

const AppRoutes = () => {
    const location = useLocation();
    const isAuthRoute = location.pathname === '/' || location.pathname === '/register';

    return (
        <div className="app flex h-screen">
            {!isAuthRoute && <Sidebar />} 
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Register />} /> 
                <Route path="/dashboard" element={<Dashboard />} /> {/* Added Dashboard route */}
                <Route path="*" element={<Main />} />
            </Routes>
        </div>
    );
};

function App() {
    return (
        <Router>
            <AppRoutes />
        </Router>
    );
}

export default App;
