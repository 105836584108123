import React from 'react';

const Attendance = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Attendance Page</h1>
      {/* Add attendance content here */}
    </div>
  );
};

export default Attendance;
