import React from 'react';

const Announcements = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">Announcements Page</h1>
      {/* Add announcements content here */}
    </div>
  );
};

export default Announcements;
