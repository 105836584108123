import React from 'react';

const TodoList = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold">To-Do List Page</h1>
      {/* Add to-do list content here */}
    </div>
  );
};

export default TodoList;
