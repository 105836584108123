
import React from 'react';

const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
            {/* Add your dashboard content here */}
        </div>
    );
};

export default Dashboard;
