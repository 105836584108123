import React from 'react';

const Profile = () => {
    return (
        <div>
          <h1 className="text-2xl font-bold">Announcements Page</h1>
        </div>
      );
};

export default Profile;
