import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { HiMegaphone } from "react-icons/hi2";
import { IoCalendar } from "react-icons/io5";
import { SiGoogletasks } from "react-icons/si";
import { IoSettings } from "react-icons/io5";
import { SiReadthedocs } from "react-icons/si";
import { IoMdInformationCircle } from "react-icons/io";
import { MdDashboard } from "react-icons/md";

const SidebarComponent = () => {
  const [isOpen, setIsOpen] = useState(false); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 

  const toggleSidebar = () => {
    setIsOpen(!isOpen); 
  };

  const handleLinkClick = () => {
    setIsOpen(false); 
    setIsDropdownOpen(false); 
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button 
                onClick={toggleSidebar} 
                aria-controls="logo-sidebar" 
                type="button" 
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg 
                  className="w-6 h-6" 
                  aria-hidden="true" 
                  fill="currentColor" 
                  viewBox="0 0 20 20" 
          
                >
                  <path 
                    clipRule="evenodd" 
                    fillRule="evenodd" 
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a 
                href="https://hrms.broadwatermarine.com/" 
                className="flex ms-2 md:me-24"
              >
                <img 
                  src="/logo.png" 
                  className="h-8 me-3" 
                  alt="" 
                />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white"></span>
              </a>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3">
                <div>
                  <button 
                    type="button" 
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" 
                    onClick={toggleDropdown}
                  >
                    <span className="sr-only">Open user menu</span>
                    <img 
                      className="w-8 h-8 rounded-full" 
                      src="./logo.svg" 
                      alt="User"
                    />
                  </button>
                </div>
                <div 
                  className={`absolute right-0 z-50 ${isDropdownOpen ? 'block' : 'hidden'} my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600`} 
                  id="dropdown-user"
                  style={{ top: '80%' }}
                >
                  <div className="px-4 py-3" role="none">
                    <p className="text-sm text-gray-900 dark:text-white" role="none">Username</p>
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">user@broadwatermarine.com</p>
                  </div>
                  <ul className="py-2" role="none">
                    <li>
                      <NavLink 
                        to="/signout" 
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" 
                        role="menuitem"
                        onClick={handleLinkClick}
                      >
                        Dark Mode
                      </NavLink>
                    </li>
                    <li>
                      <NavLink 
                        to="/signout" 
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" 
                        role="menuitem"
                        onClick={handleLinkClick}
                      >
                        Sign out
                      </NavLink>
                    </li>
                    <li>
                      <button 
                        id="theme-toggle" 
                        type="button" 
                        className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
                      >
                        <svg 
                          id="theme-toggle-dark-icon" 
                          className="hidden w-5 h-5" 
                          fill="currentColor" 
                          viewBox="0 0 20 20" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                        </svg>
                        <svg 
                          id="theme-toggle-light-icon" 
                          className="hidden w-5 h-5" 
                          fill="currentColor" 
                          viewBox="0 0 20 20" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <aside
        id="logo-sidebar"
        className={`fixed inset-y-0 left-0 z-40 w-64 h-screen pt-20 bg-blue border-r border-gray-200 transition-transform sm:relative sm:translate-x-0 ${isOpen ? 'translate-x-0' : '-translate-x-full'} dark:bg-gray-800 dark:border-gray-700`} 
        aria-label="Sidebar"
      >
        
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-4 font-medium"> 
          <li>
              <NavLink 
                to="dashboard" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
               <MdDashboard /><span className="ms-3">Dashboard</span> 
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/profile" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
                <FaUser /><span className="ms-3">Profile</span> 
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/announcements" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
                <HiMegaphone /><span className="ms-3">Announcements</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/attendance" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
                <IoCalendar /><span className="ms-3">Attendance</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/todo" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
                <SiGoogletasks /><span className="ms-3">To-Do List</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/resources" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
                <SiReadthedocs /><span className="ms-3">Resources</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/settings" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
                <IoSettings /><span className="ms-3">Settings</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/about" 
                className="flex items-center p-2 text-gray-700 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                onClick={handleLinkClick}
              >
                <IoMdInformationCircle /><span className="ms-3">About</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SidebarComponent;
