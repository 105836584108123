// src/pages/Register.jsx
import React, { useState } from 'react';

const Register = () => {

    return (
        <div>
    


        </div>
    );
};

export default Register;
